import {useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {Button, ButtonGroup, Dropdown, Form, Image} from "react-bootstrap";
import "./Poster.css"
import axios from "axios";

const Poster = () => {
    const navigate = useNavigate();
    const moviePosterPathOriginal = "https://image.tmdb.org/t/p/w500/"
    let {poster_path} = useParams();
    const [imgSrc, setImgSrc] = useState(`${moviePosterPathOriginal}${poster_path}`)
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        uhd: false,
        bluray: false,
        hdr: false,
        dolbyVision: false,
        dolbyAtmos: false,
        dtsx: false,
        trueHD: false,
        stacked: false,
        poster_path: poster_path
    })

    const handleChange = (e) => {
        let value = e.target.checked;
        setFormData({
            ...formData, [e.target.id]: value
        })
    }
    const sendData = async () => {
        setLoading(true)
        const response = await fetch('https://simple-movie-poster-s7bit.ondigitalocean.app/combine-thing', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then((response) => response.json())
        setImgSrc(`https://simple-movie-poster-s7bit.ondigitalocean.app/poster-image/${response.image_url}`)
        setLoading(false)
    }
    return (
        <div>
            <div className="d-flex justify-content-center">
                <div style={{display: loading ? "flex" : "none", justifyContent: 'center'}} className='spinner-border text-dark' role='status'></div>
            </div>
            <div className="poster">
                <img className="img-fluid" alt={poster_path} src={imgSrc} onLoad={() => setLoading(false)}/>
                <Dropdown as={ButtonGroup}>
                    <Button variant="success" onClick={sendData}>Generate</Button>

                    <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

                    <Dropdown.Menu>
                        <Form onChange={(e) => handleChange(e)}>
                            {['checkbox'].map((type) => (
                                <div key={`default-checkbox`} className="mb-3">
                                    <span id={"warning"}>*Select one from each category</span>
                                    <br/>
                                    <span>Resolution</span>
                                    <Form.Check
                                        type={type}
                                        name={'resolution'}
                                        id={`uhd`}
                                        label={`UHD`}
                                    />
                                    <Form.Check
                                        type={type}
                                        name={'resolution'}
                                        id={`bluray`}
                                        label={`Bluray`}
                                    />
                                    <span>Format</span>
                                    <Form.Check
                                        type={type}
                                        name={'format'}
                                        id={`hdr`}
                                        label={`HDR`}
                                    />
                                    <Form.Check
                                        type={type}
                                        name={'format'}
                                        id={`dolbyVision`}
                                        label={`Dolby Vision`}
                                    />
                                    <span>Audio</span>
                                    <Form.Check
                                        type={type}
                                        name={'audio'}
                                        id={`dolbyAtmos`}
                                        label={`Dolby Atmos`}
                                    />
                                    <Form.Check
                                        type={type}
                                        name={'audio'}
                                        id={`dtsx`}
                                        label={`DTS:X`}
                                    />
                                    <Form.Check
                                        type={type}
                                        name={'audio'}
                                        id={`trueHD`}
                                        label={`TrueHD`}
                                    />
                                    <span>Misc.</span>
                                    <Form.Check
                                        type={type}
                                        name={'misc'}
                                        id={`stacked`}
                                        label={`Stacked`}
                                    />
                                </div>
                            ))}
                        </Form>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}

export default Poster