
import SearchBar from "./components/SearchBar";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Posters from "./components/Posters";
import Poster from "./components/Poster";
import Header from "./components/Header";
import Combined from "./components/Combined";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <div className="App">

        <div className="content">
          <Routes>
            <Route path="/" element={<><Header/></>} />
            <Route path="/:type/:media" element={<><Header/><SearchBar /></>} />
            <Route path="/posters/:type" element={<><Header/> <Posters /> </>} />
            <Route path="/poster/:poster_path" element={<><Header/> <Poster /></>} />
            <Route path="/combine/:poster_path/:banner/:format" element={<><Header/><Combined /></>} />
          </Routes>
          <Footer/>
        </div>
      </div>
    </Router>
  );
}

export default App;
