import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
            <a>All posters provided by <a href="https://www.themoviedb.org/">The Movie Database</a></a>
            <a className="btn btn-secondary" target={"_blank"} rel={'noreferrer'} href="https://forms.gle/mwkT1qX22GkxZtAd9" role="button">🐛 Report a bug</a>
            <a className="btn btn-secondary" target={"_blank"} rel={'noreferrer'} href="https://www.buymeacoffee.com/wagnerdak1" role="button">☕ Buy me a coffee</a>
        </div>
    );
};

export default Footer

