import React from "react";
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import './Header.css'
const Header = () => {
    const [title, setTitle] = useState('');
    const navigate = useNavigate()
    const onFormSubmit = (e) => {
        if (title === '') {
            e.preventDefault();
        } else {
            navigate(`/movie/${title}`)
        }
    }
    return (
        <div id="header">
            <Link className="display-4" id="home-link" to="/">Simple Movie Posters</Link>
            <p className="display-6" id="slogan">A simple way to get your high quality movie and TV show posters</p>

            <form id='form' method={'GET'} onSubmit={onFormSubmit}>
                <input id="search-box" type="text" placeholder='Search for any movie or show' name="movie" value={title}
                       onChange={(e) => setTitle(e.target.value)}/>
            </form>
            <div className="btn-group-sm">
                <button onClick={(e) => {
                    if (title === '') {
                        e.preventDefault()
                    } else {navigate(`/movie/${title}`)}
                }} className="btn btn-secondary">Search Movies
                </button>
                <button onClick={(e) => {
                    if (title === '') {
                        e.preventDefault()
                    } else navigate(`/tv/${title}`)
                }} className="btn btn-secondary">Search TV Shows
                </button>
            </div>
        </div>
    )
}

export default Header