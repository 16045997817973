import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {Link, useParams} from 'react-router-dom';

const SearchBar = () => {
  const posterURL = "https://image.tmdb.org/t/p/w185"
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  let {type, media} = useParams()

  const getMovies = async () => {
    setLoading(true)
    const options = {
      method: 'GET',
      url: `https://simple-movie-poster-s7bit.ondigitalocean.app/${type}/${media}`,
      // url: `http://localhost:8000/${type}/${media}`,
    }
    await axios.request(options).then((response) => {
      setLoading(false)
      setMovies(response.data);
    }).catch((error) => {
      console.error(error);
    })
  }

  useEffect(() => {

    getMovies()
  }, [media]);


  return (
        <div>
          <div className="d-flex justify-content-center">
            <div style={{display: loading ? "flex" : "none", justifyContent: 'center'}} className='spinner-border text-dark' role='status'></div>
          </div>
        {movies &&
          <div className='container'>
            {movies.map((movie, i) => movie.poster_path && (
              <div key={i} className='box'>
                <Link to={`/posters/${type}?mediaID=${movie.id}`}><img className='grid-image' alt={movie.title} src={posterURL + movie.poster_path} /></Link>
                <Link to={`/posters/${type}?mediaID=${movie.id}`} className='movie-poster-link'>{`${movie.title} (${movie.release_date})`}</Link>
              </div>
            ))}
          </div>
        }
          {/*<p style={{ fontSize: '25px',color: "red", display: "flex", justifyContent: "center"}}>The Movie Database's API is currently down.</p>*/}

    </div>
  )
}

export default SearchBar