import React, {useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import axios from "axios";

const Posters = () => {
    const posterURL = "https://image.tmdb.org/t/p/w185"
    let {type} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [moviePosters, setMoviePosters] = useState();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const getPosters = async () => {
            const options = {
                method: 'GET',
                url: `https://simple-movie-poster-s7bit.ondigitalocean.app/poster/${type}?mediaID=${searchParams.get("mediaID")}`,
                // url: `http://localhost:8000/poster/${type}?mediaID=${searchParams.get("mediaID")}`

            }
            axios.request(options).then((response) => {
                setLoading(false)
                setMoviePosters(response.data);

            }).catch((error) => {
                console.error(error);
            })
        }
        getPosters();
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-center">
                <div style={{display: loading ? "flex" : "none", justifyContent: 'center'}} className='spinner-border text-dark' role='status'></div>
            </div>
            {moviePosters &&
                <div className="container">
                    {moviePosters.map((poster, i) => (
                        <div key={i} className="box">
                            <Link className="movie-poster-link" to={`/poster${poster.poster_path}`}><img
                                className="grid-image" alt={poster.poster_path}
                                src={posterURL + poster.poster_path}></img></Link>
                        </div>
                    ))}</div>
            }
        </div>
    )
}

export default Posters