import React, {useState} from 'react';
import {useParams} from "react-router-dom";

const Combined = () => {
    const [loading, setLoading] = useState(true);
    let {poster_path, banner, format} = useParams();
    const combinedPoster = `https://simple-movie-poster-s7bit.ondigitalocean.app/combine/${poster_path}/${banner}/${format}`
    // const combinedPoster = `http://localhost:8000/combine/${poster_path}/${banner}/${format}`
    return (

        <div className="poster" id="main-poster">
            <img style={{display: loading ? "none" : "block"}} className="img-fluid" alt={poster_path}
                 src={combinedPoster} onLoad={() => setLoading(false)}></img>
            <div style={{display: loading ? "block" : "none"}} className='spinner-border text-dark' role='status'></div>
        </div>
    );
};

export default Combined;